import { createSlice } from '@reduxjs/toolkit';

const trafficInitState = {
  paths: {},
  vesselLocationFrames: {},
  heatmapFrames: {},
  closeEncounterFrames: {},
};

const trafficSlice = createSlice({
  name: "traffic",
  initialState: trafficInitState,
  reducers: {
    setTraffic: (state, { payload }) =>{
      state.paths = {};
      // eslint-disable-next-line
      payload.paths.map((path) => {
        state.paths[path.mmsi] = path.path_locations;
      });

      state.vesselLocationFrames = {};
      state.heatmapFrames = {}
      state.closeEncounterFrames = {};
      // eslint-disable-next-line
      payload.snapshots.map((snapshot) => {
        state.vesselLocationFrames[snapshot.timestamp] = snapshot.vessel_locations;
        state.heatmapFrames[snapshot.timestamp] = snapshot.heatmap;
        state.closeEncounterFrames[snapshot.timestamp] = snapshot.close_encounters;
      });
    },
    setPaths: (state, { payload }) => {
        state.paths = payload;
    },
    setVesselLocationFrames: (state, { payload }) =>{
        state.vesselLocationFrames = payload;
    },
    setHeatmapFrames: (state, { payload }) =>{
      state.heatmapFrames = payload;
  },
    setCloseEncounterFrames: (state, { payload }) =>{
        state.closeEncounterFrames = payload;
    },    
  },
});

export const {
    setTraffic: setTrafficActionCreator,
    setPaths: setPathsActionCreator,
    setVesselLocationFrames: setVesselLocationFramesActionCreator,
    setHeatmapFrames: setHeatmapFramesActionCreator,
    setCloseEncounterFrames: setCloseEncounterFramesActionCreator,
} = trafficSlice.actions;

export default trafficSlice;