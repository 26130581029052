import React from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { togglePanelOpenActionCreator } from "store/reducers/panelOpenSlice";

import VesselDetails from "./VesselDetails";
// import AlertDetails from "./AlertDetails";
import CollapseButton from "components/common/CollapseButton";
import DataTable from "components/common/icons/data-table";
import ArrowRight from "components/common/icons/arrow-right";
import Close from "components/common/icons/close";

const RightPanelBox = styled.div`
  height: 100%;
  width: ${(props) => isMobile ?
    2 * props.theme.sidePanel.margin.left :
    props.theme.sidePanel.width + 2 * props.theme.sidePanel.margin.left}px;
  position: relative;
`;

const StyledDetailsPanelContainer = styled.div`
  z-index: 99;
  height: 100%;
  width: ${(props) => props.width + 2 * props.theme.sidePanel.margin.left}px;
  display: flex;
  position: relative;
  padding-top: ${(props) => props.theme.sidePanel.margin.top}px;
  padding-right: ${(props) => props.theme.sidePanel.margin.left}px;
  padding-bottom: ${(props) => props.theme.sidePanel.margin.bottom}px;
  padding-left: ${(props) => props.theme.sidePanel.margin.right}px;
  transition: ${(props) => props.theme.transitionFast};
`;

const DetailsPanelInner = styled.div`
  box-shadow: ${(props) => props.theme.panelBoxShadow};
  align-items: stretch;
  background-color: ${(props) => props.theme.sidePanelBg};
  border-radius: 5px;
  max-height: 100%;
  width: ${(props) => props.theme.sidePanel.width}px;
`;

const PlaceholderContainer = styled.div`
  background-color: ${(props) => props.theme.sidePanelBg};
  color: ${(props) => props.theme.textColor};
  font-size: 1.2em;
  text-align: center;
  padding: 10px;
`;

const ControlButton = ({panel, onClick}) => {
  if (panel.isOpen){
    if(isMobile){
      return (
        <CollapseButton onClick={onClick} style={{ width: "40px", height: "40px", right: "-20px" }}>
          <Close height="25px"/>
        </CollapseButton>
      );
    } else {
      return (
        <CollapseButton onClick={onClick} style={{ left: "-5px" }}>
          <ArrowRight height="12px"/>
        </CollapseButton>
      );
    }
  } else {
    return (
      <CollapseButton onClick={onClick} style={{ width: "40px", height: "40px", left: `${isMobile ? "20px" : "-20px"}` }}>
        <DataTable height="25px"/>
      </CollapseButton>
    );
  }
}

const DetailsPanel = () => {
  // Redux states
  const dispatch = useDispatch();
  const panelOpen = useSelector((state) => state.panelOpen);
  const activeVessels = useSelector(
    (state) => state.vesselData.activeVesselData
  );
  // const alertVessels = useSelector((state) => state.vesselData.alertVesselData);
  const activeFuturePath = useSelector(
    (state) => state.pathData.activeFuturePath
  );
  const activeHistoricalPath = useSelector(
    (state) => state.pathData.activeHistoricalPath
  );

  const togglePanelOpen = (panelKey) => {
    dispatch(togglePanelOpenActionCreator(panelKey));
  };

  // Component constants
  const width = 300;
  const panelKey = "detailsPanel";
  const panel =
    panelOpen[Object.keys(panelOpen).find((key) => key === panelKey)];

  const handleOnClick = (event) => {
    togglePanelOpen(panelKey);
  };

  return (
    <RightPanelBox className="flex flex-row-reverse space-x-reverse" style={{ visibility: `${panel.isHidden ? "collapse" : "visible"}`, left: `${isMobile ? "-30px" : "0px"}` }} >
      <StyledDetailsPanelContainer width={panel.isOpen ? width : 0}>
        {panel.isOpen && (
          <DetailsPanelInner>
            {/*
            {activeVessels.length > 0 &&
            (activeHistoricalPath.length > 0 || activeFuturePath.length > 0) ? (
              <VesselDetails
                vessel={activeVessels[0]}
                historicalPath={activeHistoricalPath[0]}
                futurePath={activeFuturePath[0]}
              />
            ) : alertVessels.length > 0 ? (
              <AlertDetails alertVessels={alertVessels}></AlertDetails>
            ) : (
              <div className="flex content-center flex-wrap flex-auto h-full p-5">
                <PlaceholderContainer className="flex-auto">
                  No active vessel selected
                </PlaceholderContainer>
              </div>
            )}
            */}
            {activeVessels.length > 0 &&
            (activeHistoricalPath.length > 0 || activeFuturePath.length > 0) ? (
              <VesselDetails
                vessel={activeVessels[0]}
                historicalPath={activeHistoricalPath[0]}
                futurePath={activeFuturePath[0]}
              />
            ) : (
              <div className="flex content-center flex-wrap flex-auto h-full p-5">
                <PlaceholderContainer className="flex-auto">
                  No active vessel selected
                </PlaceholderContainer>
              </div>
            )}
          </DetailsPanelInner>
        )}
        <ControlButton onClick={handleOnClick} panel={panel} />
      </StyledDetailsPanelContainer>
    </RightPanelBox>
  );
};

export default DetailsPanel;
