import { createSlice } from '@reduxjs/toolkit';

const layerVisibilityInitialState = {
    fairwayPolygon: { layerName: "Fairways", visible: true },
    lanePolygon: { layerName: "Lanes and Boundaries", visible: true },
    mooringPolygon: { layerName: "Mooring Areas", visible: true },
    anchorangePolygon: { layerName: "Anchorage Areas", visible: true },
    trafficHeatmap: { layerName: "Traffic Density Heatmap", visible: true },
    /*
    riskHeatmap: { layerName: "Collision Risk Heatmap", visible: false },
    riskScreenGrid: { layerName: "Collision Risk Screen Grid", visible: false },
    riskHexagon: { layerName: "Collision Risk Hexagon Grid", visible: false },
    */
    historicalPath: { layerName: "Historical Path", visible: true },
    futurePath: { layerName: "Future Path", visible: true },
    // riskPath: { layerName: "High-risk Vessel Path", visible: false },
    movingVesselIcon: { layerName: "Moving Vessels", visible: true },
    stoppedVesselIcon: { layerName: "Stopped Vessels", visible: false },
    // alertIcon: { layerName: "Alerts", visible: false },
};
  
const layerVisibilitySlice = createSlice({
  name: "layerVisibility",
  initialState: layerVisibilityInitialState,
  reducers: {
    toggleVisibility: (state, { payload }) => {
      const layer =
        state[
          Object.keys(state).find((key) => state[key].layerName === payload)
        ];
      layer.visible = !layer.visible;
    },
  },
});

export const {
  toggleVisibility: toggleLayerVisibilityActionCreator,
} = layerVisibilitySlice.actions;

export default layerVisibilitySlice;