import { createSlice } from '@reduxjs/toolkit';

const heatmapDataSlice = createSlice({
  name: "heatmap",
  initialState: {
    heatmapData: []
  },
  reducers: {
    setHeatmap: (state, { payload }) => {
        state.heatmapData = payload;
    }
  }
});

export const{
    setHeatmap: setHeatmapActionCreator,
} = heatmapDataSlice.actions;

export default heatmapDataSlice;