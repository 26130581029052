import { createSlice } from '@reduxjs/toolkit';

const pathDataInitialState = [
  {
    path: [],
    timestamps: [],
    speed: [],
    recommend_speed: [],
    heading: [],
    course: [],
    // risk: [],
  },
];
  
const pathDataSlice = createSlice({
  name: "pathData",
  initialState: {
    activePathData: [],
    activeFuturePath: pathDataInitialState,
    activeHistoricalPath: pathDataInitialState,
    // highRiskPathData: [],
    // alertPathData: [],
  },
  reducers: {
    setActivePath: (state, { payload }) => {
      state.activePathData = payload;
    },
    setActiveFuturePath: (state, { payload }) => {
      state.activeFuturePath = payload;
    },
    setActiveHistoricalPath: (state, { payload }) => {
      state.activeHistoricalPath = payload;
    },
    /*
    setHighRiskPaths: (state, { payload }) => {
      state.highRiskPathData = payload;
    },
    setAlertPaths: (state, { payload }) => {
      state.alertPathData = payload;
    },
    */
  },
});

export const {
  setActivePath: setActivePathActionCreator,
  setActiveFuturePath: setActiveFuturePathActionCreator,
  setActiveHistoricalPath: setActiveHistoricalPathActionCreator,
  /*
  setHighRiskPaths: setHighRiskPathsActionCreator,
  setAlertPaths: setAlertPathsActionCreator,
  */
} = pathDataSlice.actions;

export default pathDataSlice;