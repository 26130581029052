import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";

import styled from "styled-components";

import Slider from "@material-ui/core/Slider";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";

import { getShortTimeString } from "utilities/datetime";
import { togglePanelOpenActionCreator } from "store/reducers/panelOpenSlice";
import { 
  setCurrentFrameActionCreator, 
  getFrameTime,
} from "store/reducers/framesSlice";
import CollapseButton from "components/common/CollapseButton";
import Clock from "components/common/icons/clock";
import ArrowDown from "components/common/icons/arrow-down";


import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const BottomPanelBox = styled.div`
  width: 100%;
  height: ${(props) =>
    props.height + 2 * props.theme.sidePanel.margin.bottom}px;
  position: relative;
`;

const StyledTimeSliderContainer = styled.div`
  z-index: 99;
  height: ${(props) =>
    props.height + 2 * props.theme.sidePanel.margin.bottom}px;
  max-width: 100%;
  display: flex;
  position: relative;
  padding-top: ${(props) => props.theme.sidePanel.margin.bottom}px;
  padding-bottom: ${(props) => props.theme.sidePanel.margin.bottom}px;
  padding-right: 0px;
  padding-left: 0px;
  transition: ${(props) => props.theme.transitionFast};
`;

const TimeSliderInner = styled.div`
  align-items: stretch;
  box-shadow: ${(props) => props.theme.panelBoxShadow};
  background-color: ${(props) => props.theme.sidePanelBg};
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.textColor};
  opacity: 0.6;
  border: none;
  :focus {
    outline: 0;
  }
  :hover {
    opacity: 1;
  }
`;

/*
const StyledSlider = withStyles({
  root: {
    color: "#00d672",
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -4,
    // marginLeft: -6,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 8,
  },
  rail: {
    height: 8,
    backgroundColor: "#00d672",
  },
})(Slider);
*/

const StyledSlider = styled(Slider)`
  color: #00D672;
  font-size: 0.75em;

  .MuiSlider-rail{
    height: 8px;
  }

  .MuiSlider-track{
    height: 8px;
  }

  .MuiSlider-thumb{
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 2px solid currentColor;
    margin-top: -4px;
  }

  .MuiSlider-markLabel{
    color: ${(props) => props.theme.subtextColor};
  }

  .MuiSlider-markLabelActive{
    color: ${(props) => props.theme.textColor};
  }
`;

// const StyledCircularProgress = withStyles({
//   root: {
//     color: "#00d672",
//     marginTop: 10,
//     animationDuration: "0ms",
//   },
// })(CircularProgress);

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-circle {
    color: #00D672;
  }
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.textColor};
  font-size: 0.8em;
`;

// const CircularProgressWithLabel = (props) => {
//   return (
//     <Box
//       position="relative"
//       display="flex"
//       alignItems="center"
//       justifyContent="center"
//       top={15}
//     >
//       <StyledCircularProgress {...props} />
//       <Box
//         top={10}
//         left={0}
//         bottom={0}
//         right={0}
//         position="absolute"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//       >
//         <Typography
//           variant="caption"
//           component="div"
//           color="textSecondary"
//         >
//           {`${Math.round(props.value)}%`}
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

const CircularProgressWithLabel = (props) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      top={15}>
      <StyledCircularProgress 
        size="4em"
        defaultValue={0} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <StyledTypography
          component="div">
          {`${props.value}`}
        </StyledTypography>
      </Box>
    </Box>
  );
};

const ControlButton = ({panel, onClick}) => {
  if (panel.isOpen){
    if(isMobile){
      return (
        <CollapseButton onClick={onClick} style={{ width: "40px", height: "40px", top: "-10px" }}>
          <ArrowDown height="25px"/>
        </CollapseButton>
      );
    } else {
      return (
        <CollapseButton onClick={onClick} style={{ top: "8px" }}>
          <ArrowDown height="12px"/>
        </CollapseButton>
      );
    }
  } else {
    return (
      <CollapseButton onClick={onClick} style={{ width: "40px", height: "40px", top: `${isMobile ? "-20px" : "-10px"}` }}>
        <Clock height="25px"/>
      </CollapseButton>
    );
  }
}

const TimeSlider = () => {
  // Redux states
  const dispatch = useDispatch();
  const panelOpen = useSelector((state) => state.panelOpen);

  const togglePanelOpen = (panelKey) => {
    dispatch(togglePanelOpenActionCreator(panelKey));
  };

  const currentFrame = useSelector((state) => state.frames.currentFrame);
  const totalFrames = useSelector((state) => state.frames.totalFrames);
  const marks = useSelector((state) => state.frames.marks);

  const setCurrentFrame = (frame) => {
    dispatch(setCurrentFrameActionCreator(frame));
  };

  function valueLabelFormat() {
    return getShortTimeString(sliderTime);
  }

  // Component constants
  const height = 90;
  const panelKey = "timeSlider";
  const panel =
    panelOpen[Object.keys(panelOpen).find((key) => key === panelKey)];

  const handleOnClick = (event) => {
    togglePanelOpen(panelKey);
  };

  const [sliderValue, setSliderValue] = useState(currentFrame);
  const sliderTime = useSelector(getFrameTime(sliderValue));

  const handleSliderChange = (event, value) => {
    setSliderValue(value);
    setCurrentFrame(value);
  };

  const [playing, setPlaying] = useState(false);
  const togglePlayPause = (event) => {
    setPlaying(!playing);
  };

  useEffect(() => {
    let interval = null;
    const timePerFrame = 300; // ms
    if (playing) {
      interval = setInterval(() => {
//        if (sliderValue === totalFrames - 1) {
        if (sliderValue === totalFrames) {
          setSliderValue(0);
          setCurrentFrame(0);
        } else {
          setSliderValue((sliderValue) => sliderValue + 1);
          setCurrentFrame(sliderValue);
        }
      }, timePerFrame);
    } else if (!playing && sliderValue !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, sliderValue]);

//   const [progress, setProgress] = useState(0);

//   useEffect(() => {
//     setProgress((loadedFrames / (totalFrames + 1)) * 100);
//   }, [loadedFrames, totalFrames]);

  return (
    <BottomPanelBox className="flex-1 flex flex-col-reverse space-y-reverse" style={{ visibility: `${panel.isHidden ? "collapse" : "visible"}` }} >
      <StyledTimeSliderContainer height={panel.isOpen ? height : 0}>
        {panel.isOpen && (
          <TimeSliderInner>
            {
              currentFrame < 0 ? (  
/*                
                <CircularProgressWithLabel value={progress} />
*/
                <CircularProgressWithLabel value="Loading" />
              ) : (
              <div className="items-stretch flex flex-row p-2">
                <StyledIconButton onClick={togglePlayPause}>
                  {playing ? (
                    <PauseCircleFilledIcon />
                  ) : (
                    <PlayCircleFilledIcon />
                  )}
                </StyledIconButton>

                <div className="px-5 flex-auto -mb-6">
                  { 
/*
                  <StyledSlider
                    value={sliderValue}
                    min={0}
                    max={totalFrames - 1}                    
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    marks={marks}
                    valueLabelFormat={valueLabelFormat}
                  /> 
*/
                  <StyledSlider
                    value={sliderValue}
                    min={0}
                    max={totalFrames}                    
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    marks={marks}
                    valueLabelFormat={valueLabelFormat}
                  /> 
                  }
                </div>
              </div>
              )
            }
          </TimeSliderInner>
        )}
        <ControlButton onClick={handleOnClick} panel={panel} />
      </StyledTimeSliderContainer>
    </BottomPanelBox>
  );
};

export default TimeSlider;
