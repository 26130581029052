import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  // setVesselRiskFilterRangeActionCreator,
  setVesselSpeedFilterRangeActionCreator,
} from "store/reducers/vesselSliderFilterSlice";

import SliderFilter from "./SliderFilter";

const FilterWidgetContainer = styled.div`
  background-color: ${(props) => props.theme.sidePanelHeaderBg};
  color: ${(props) => props.theme.textColor};
  padding: 6px;
  margin-bottom: 8px;
  max-height: 250px;
`;

const VesselSliderFilters = () => { 
  // Redux state
  const dispatch = useDispatch();
  const vesselSliderFilter = useSelector((state) => state.vesselSliderFilter);

  const allVessels = useSelector((state) => state.vesselData.allVesselData);
  const movingVessels = allVessels.filter((vessel) => {
    return vessel.speed >= 1;
  });
  /*
  const setRiskRange = (newRange) => {
    dispatch(setVesselRiskFilterRangeActionCreator(newRange));
  };
  */
  const setSpeedRange = (newRange) => {
    dispatch(setVesselSpeedFilterRangeActionCreator(newRange));
  };

  const getBinSize = (domain) => {
    return Math.ceil((domain[1] - domain[0]) / 20 * 100) / 100;
  };
  /*
  const riskData = movingVessels.map(({ risk }) => risk);
  // Risk Domain and bin size should be based on 
  let riskDomain = [0, 20];
  if (riskData.length > 0) {
    let minRisk = Math.floor(Math.min(...riskData) * 100) / 100;
    let maxRisk = Math.ceil(Math.max(...riskData) * 100) / 100;
    riskDomain = [ minRisk, maxRisk ];
  }
  const riskBinSize = getBinSize(riskDomain);
  */
  const speedData = movingVessels.map(({ speed }) => speed);
  let speedDomain = [0, 35];
  if (speedData.length > 0){
    let minSpeed = Math.floor(Math.min(...speedData) * 100) / 100;
    let maxSpeed = Math.ceil(Math.max(...speedData) * 100) / 100;
    speedDomain = [minSpeed, maxSpeed];
  }
  const speedBinSize = getBinSize(speedDomain);

  return (
    <div>
      {/*
      <FilterWidgetContainer>
        <div className="px-1 mb-1">
          <b>Vessel Risk Filter</b>
        </div>
        
        <SliderFilter
          data={riskData}
          domain={riskDomain}
          binSize={riskBinSize}
          reduxRange={vesselSliderFilter.risk}
          setReduxRange={setRiskRange}
        />
      </FilterWidgetContainer>
      */}
      <FilterWidgetContainer>
        <div className="px-1 mb-1">
          <b>Vessel Speed Filter</b>
        </div>
        <SliderFilter
          data={speedData}
          domain={speedDomain}
          binSize={speedBinSize}
          reduxRange={vesselSliderFilter.speed}
          setReduxRange={setSpeedRange}
        />
      </FilterWidgetContainer>
    </div>
  );
};

export default VesselSliderFilters;
