import { createSlice } from '@reduxjs/toolkit';

const dataSourceControlSlice = createSlice({
  name: "dataSourceControl",
  initialState: {
    currentTime: 1546273002,
  },
  reducers: {
    setCurrentTime: (state, { payload }) => {
      state.currentTime = payload;
    },
    reset: (state) => {
      state.currentTime = 1546273002;
    },
  },
});

export const {
  setCurrentTime: setCurrentTimeActionCreator,
  reset: resetActionCreator,
} = dataSourceControlSlice.actions;

export default dataSourceControlSlice;