import { createSlice } from '@reduxjs/toolkit';

const vesselDataSlice = createSlice({
  name: "vesselData",
  initialState: {
    allVesselData: [],
    activeVesselData: [],
    /*
    alertVesselData: [],
    alertColors: [
      {
        border: "#530354",
        borderRGB: [83, 3, 84],
        fill: "#A004A4",
        fillRGB: [160, 4, 164],
      },
      {
        border: "#AB4700",
        borderRGB: [171, 71, 0],
        fill: "#FF6A00",
        fillRGB: [255, 106, 0],
      },
    ],
    */
  },
  reducers: {
    setAllVessels: (state, { payload }) => {
      state.allVesselData = payload;
    },
    setActiveVessels: (state, { payload }) => {
      state.activeVesselData = payload;
    },
    /*
    setAlertVessels: (state, { payload }) => {
      state.alertVesselData = payload;
    },
    */
  },
});

export const {
  setAllVessels: setAllVesselsActionCreator,
  setActiveVessels: setActiveVesselsActionCreator,
  // setAlertVessels: setAlertVesselsActionCreator,
} = vesselDataSlice.actions;

export default vesselDataSlice;