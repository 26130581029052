import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components";
import { useTheme } from "styled-components";

import DateFnsUtils from "@date-io/date-fns";

import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { 
  TextField, 
} from "@material-ui/core";

import { 
  getTimestamp, 
  getDate 
} from "utilities/datetime";

import { 
  setMetadataActionCreator,
} from "store/reducers/framesSlice";

const DataSourceWidgetContainer = styled.div`
  background-color: ${(props) => props.theme.sidePanelHeaderBg};
  color: ${(props) => props.theme.textColor};
  padding: 6px;
  margin-bottom: 8px;
`;

// const StyledLabel = styled.div`
//   background-color: ${(props) => props.theme.labelColor};
//   color: ${(props) => props.theme.labelTextColor};
//   font-size: 0.75em;
//   padding: 2px 2px 2px 2px;
//   height: 25px;
//   width: 100px;
// `;

// const StyledInput = styled.input`
//   color: ${(props) => props.theme.textColor};
//   background-color: ${(props) => props.theme.sidePanelBg};
//   font-size: 0.8em;
//   height: 25px;
//   padding: 2px;
//   border: none;
//   :focus {
//     outline: 0;
//   }
// `;

// const StyledDateTimePicker = styled(function ({ className, onChange, value }) {
//   return (
//     <DateTimePicker
//       className={"flex-auto " + className}
//       autoFocus={false}
//       calendarIcon={null}
//       disableClock={true}
//       format={"yyyy/MM/dd H:mm:ss"}
//       calendarClassName={className}
//       onChange={(event, newValue) => onChange(newValue)}
//       value={value}
//     />
//   );
// })`
//   font-size: 0.75em;

//   .react-datetime-picker__wrapper {
//     background-color: ${(props) => props.theme.sidePanelBg};
//     color: ${(props) => props.theme.textColor};
//     border: none;
//   }

//   .react-datetime-picker__inputGroup__input {
//     color: ${(props) => props.theme.textColor};
//     :focus {
//       outline: 0;
//     }
//   }

//   .react-datetime-picker__inputGroup__input:invalid {
//     background-color: ${(props) => props.theme.sidePanelBg};
//   }

//   .react-datetime-picker__button {
//     border: 0;
//     background: transparent;
//     padding: 4px 6px;
//     :focus {
//       outline: 0;
//     }
//   }
//   .react-datetime-picker__button:enabled {
//     cursor: pointer;
//   }
//   .react-datetime-picker__button__icon {
//     height: 12px;
//     stroke: ${(props) => props.theme.textColor};
//   }
//   .react-datetime-picker__button:enabled:hover
//     .react-datetime-picker__button__icon {
//     stroke: ${(props) => props.theme.textColor};
//   }
//   ,
//   .react-datetime-picker__button:enabled:focus
//     .react-datetime-picker__button__icon {
//     stroke: ${(props) => props.theme.textColor};
//   }

//   .react-datetime-picker__calendar .react-calendar {
//     padding: 2px;
//     margin: 0px;
//     font-size: 0.8em;
//     box-shadow: ${(props) => props.theme.panelBoxShadow};
//     background-color: ${(props) => props.theme.sidePanelBg};
//     color: ${(props) => props.theme.textColor};
//     border: none;
//   }
// `;

const StyledNumberTextField = styled(TextField)`
  height: 25px;
  width: 270px;
  border: none;
  .MuiInputBase-input {
    background-color: ${(props) => props.theme.sidePanelBg};
    color: ${(props) => props.theme.textColor};
    padding-left: 5px;
    font-size: 0.8em;
  }
  .MuiPickersToolbar-toolbar: {
    background-color: ${"#0A9DFF"};
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.textColor};
  }
`;

const StyledButton = styled.button`
  box-shadow: ${(props) => props.theme.boxShadow};
  background: ${(props) => props.theme.primaryBtnBgd};
  color: ${(props) => props.theme.primaryBtnColor};
  font-size: 0.75em;
  border: none;
  border-radius: 6px;
  :focus {
    outline: 0;
  }
`;

const StyledKeyboardDateTimePicker = styled(KeyboardDateTimePicker)`
  height: 25px;
  width: 270px;
  border: none;
  .MuiInputBase-input {
    background-color: ${(props) => props.theme.sidePanelBg};
    color: ${(props) => props.theme.textColor};
    padding-left: 5px;
    font-size: 0.8em;
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.textColor};
  }
  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.textColor};
  }
`;

const useCalendarStyles = makeStyles ({
  paper: {
    backgroundColor: props => props.sidePanelBg,
    color: props => props.textColor,
    boxShadow: props => props.panelBoxShadow,
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: "#00D672",
    },
    '& .MuiPickerDTTabs-tabs': {
      backgroundColor: "#00D672",
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: props => props.sidePanelBg,
      '& .MuiSvgIcon-root': {
        fill: props => props.textColor,
      }
    },
    '& .MuiPickersYear-yearSelected': {
      color: "#00D672",
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: props => props.subtextColor,
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: "#00D672",
      '& .MuiTypography-root': {
        color: "#FFF",
      },
    },
    '& .MuiPickersDay-day': {
      color: props => props.textColor,
    },
    '& .MuiPickersClock-clock': {
      backgroundColor: props => props.sidePanelHeaderBg,
    },
    '& .MuiPickersClock-pin':{
      backgroundColor: "#00D672",
    },
    '& .MuiPickersClockPointer-pointer':{
      backgroundColor: "#00D672",
    },
    '& .MuiPickersClockPointer-noPoint':{
      backgroundColor: "#00D672",
    },
    '& .MuiPickersClockPointer-thumb':{
      border: "14px solid #00D672",
    },
    '& span.MuiPickersClockNumber-clockNumberSelected': {
      color: "#FFF",
    },
    '& .MuiPickersClockNumber-clockNumber': {
      color: props => props.textColor,
    },
  },
});

const DataFrameSourceControl = () => {
  const DATE_TIME_FORMAT = process.env.REACT_APP_DATE_TIME_FORMAT;

  const metadata = useSelector((state) => state.frames.metadata);
  const [startTime, setStartTime] = useState(metadata.startTime);
  const [endTime, setEndTime] = useState(metadata.endTime);
  const [interval, setInterval] = useState(metadata.interval); 

  // Redux state
  const dispatch = useDispatch();
  
  const updateStartTime = (time) => {
    setStartTime(getTimestamp(time));
  }

  const updateEndTime = (time) => {
    setEndTime(getTimestamp(time));
  }

  const updateRefreshInterval = (interval) => {
    const integerRegex = /^[0-9\b]+$/;
    if(interval === "" || integerRegex.test(interval)){
      setInterval(interval);
    }
  }

  const resetInputs = () => {
    setStartTime(metadata.startTime);
    setEndTime(metadata.endTime);
    setInterval(metadata.interval);
  };

  const updateFrames = () => {
    if(startTime < endTime){
      const updatedMetadata = {
        startTime : startTime,
        endTime: endTime,
        interval: interval
      }
      dispatch(setMetadataActionCreator(updatedMetadata));
    } else {
      resetInputs();
    }
  };
  
  const theme = useTheme();
  const calendarClasses = useCalendarStyles(theme);
  
  return (
    <DataSourceWidgetContainer>
      <div className="px-1 mb-1">
        <b>Data Source</b>
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="px-1 mb-8 flex flex-row items-stretch">
          <StyledKeyboardDateTimePicker
            autoOk
            ampm={false}
            variant="inline"
            margin="dense"
            label="Start Time"
            value={getDate(startTime)}
            onChange={(event, newValue) => updateStartTime(newValue)}
            format={DATE_TIME_FORMAT}
            InputProps={{
              disableUnderline: true,
            }}
            PopoverProps={{classes:calendarClasses}}
          />
        </div>
        <div className="px-1 mb-8 flex flex-row items-stretch">
          <StyledKeyboardDateTimePicker
            autoOk
            ampm={false}
            variant="inline"
            margin="dense"
            label="End Time"
            value={getDate(endTime)}
            onChange={(event, newValue) => updateEndTime(newValue)}
            format={DATE_TIME_FORMAT}
            InputProps={{
              disableUnderline: true,
            }}
            PopoverProps={{classes:calendarClasses}}
          />
        </div>
      </MuiPickersUtilsProvider>
      <div className="px-1 mb-8 flex flex-row items-stretch">
        <StyledNumberTextField 
          value={interval}
          onChange={(event) => updateRefreshInterval(event.target.value)}
          margin="dense"
          label="Refresh Interval"
          type="number"
          placeholder="Set interval in seconds..."
          InputProps={{
            disableUnderline: true,
          }}
        />
      </div>
      <div className="py-1">
        <StyledButton className="px-2 mr-2" onClick={resetInputs}>
          Reset
        </StyledButton>
        <StyledButton className="px-2 mr-2" onClick={updateFrames}>
          Update
        </StyledButton>
      </div>
    </DataSourceWidgetContainer>
  );
};

export default DataFrameSourceControl;
