import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: "darkThemeEnabled",
  initialState: false,
  reducers: {
    toggle: (state) => !state,
  },
});

export const { 
  toggle: toggleThemeActionCreator 
} = themeSlice.actions;

export default themeSlice;