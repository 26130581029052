import { createSlice } from '@reduxjs/toolkit';

const closeEncounterDataSlice = createSlice({
  name: "closeEncounter",
  initialState: {
    allCloseEncounterData: []
  },
  reducers: {
    setAllCloseEncounters: (state, { payload }) => {
        state.allCloseEncounterData = payload;
    }
  }
});

export const{
    setAllCloseEncounters: setAllCloseEncountersActionCreator,
} = closeEncounterDataSlice.actions;

export default closeEncounterDataSlice;