import { parse, format } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc  } from "date-fns-tz";

const DATE_TIME_FORMAT = process.env.REACT_APP_DATE_TIME_FORMAT;
const TIME_FORMAT = process.env.REACT_APP_TIME_FORMAT;
const SHORT_TIME_FORMAT = process.env.REACT_APP_SHORT_TIME_FORMAT;
const TIME_ZONE = process.env.REACT_APP_TIME_ZONE;

export const getTimeString = (timestamp) => {
  let formattedTime = format(getDate(timestamp), TIME_FORMAT);
  return formattedTime;
};

export const getShortTimeString = (timestamp) => {
  let formattedTime = format(getDate(timestamp), SHORT_TIME_FORMAT);
  return formattedTime;
};
  
export const getTimestamp = (dateString) => {
  let date = parse(dateString, DATE_TIME_FORMAT, new Date());
  let utcDate = zonedTimeToUtc(date, TIME_ZONE);
  return utcDate.getTime() / 1000;
}
  
export const getDate = (timestamp) => {
  let date = utcToZonedTime(timestamp * 1000, TIME_ZONE);
  return date;
}