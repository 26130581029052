import { createSlice } from '@reduxjs/toolkit';
/*
const vesselTypeFilterInitialState = [
  { vesselType: "Not Available", filterState: true, visible: true },
  { vesselType: "Reserved", filterState: true, visible: true },
  { vesselType: "Wing In Grnd", filterState: true, visible: true },
  { vesselType: "SAR Aircraft", filterState: true, visible: true },
  { vesselType: "Fishing", filterState: true, visible: true },
  { vesselType: "Tug", filterState: true, visible: true },
  { vesselType: "Dredger", filterState: true, visible: true },
  { vesselType: "Dive Vessel", filterState: true, visible: true },
  { vesselType: "Military Ops", filterState: true, visible: true },
  { vesselType: "Sailing Vessel", filterState: true, visible: true },
  { vesselType: "Pleasure Craft", filterState: true, visible: true },
  { vesselType: "High-Speed Craft", filterState: true, visible: true },
  { vesselType: "Pilot Vessel", filterState: true, visible: true },
  { vesselType: "SAR", filterState: true, visible: true },
  { vesselType: "Port Tender", filterState: true, visible: true },
  { vesselType: "Anti-Pollution", filterState: true, visible: true },
  { vesselType: "Law Enforce", filterState: true, visible: true },
  { vesselType: "Local Vessel", filterState: true, visible: true },
  { vesselType: "Medical Trans", filterState: true, visible: true },
  { vesselType: "Special Craft", filterState: true, visible: true },
  { vesselType: "Passenger", filterState: true, visible: true },
  { vesselType: "Cargo", filterState: true, visible: true },
  { vesselType: "Cargo - Hazard A (Major)", filterState: true, visible: true },
  { vesselType: "Cargo - Hazard B", filterState: true, visible: true },
  { vesselType: "Cargo - Hazard C (Minor)", filterState: true, visible: true },
  { vesselType: "Cargo - Hazard D (Recognizable)", filterState: true, visible: true },
  { vesselType: "Tanker", filterState: true, visible: true },
  { vesselType: "Tanker - Hazard A (Major)", filterState: true, visible: true },
  { vesselType: "Tanker - Hazard B", filterState: true, visible: true },
  { vesselType: "Tanker - Hazard C (Minor)", filterState: true, visible: true },
  { vesselType: "Tanker - Hazard D (Recognizable)", filterState: true, visible: true },
  { vesselType: "Other", filterState: true, visible: true },
];
*/

const vesselTypeFilterInitialState = [
  { vesselType: "Passenger", filterState: true, visible: true },
  { vesselType: "Cargo", filterState: true, visible: true },
  { vesselType: "Tanker", filterState: true, visible: true },
];

  
const vesselTypeFilterSlice = createSlice({
  name: "vesselTypeFilter",
  initialState: vesselTypeFilterInitialState,
  reducers: {
    toggle_filter: (state, { payload }) => {
      const index = state.findIndex((data) => data.vesselType === payload);
      state[index].filterState = !state[index].filterState;
    },
    search_filter: (state, { payload }) => {
      state.forEach((elem) => {
        if (elem.vesselType.toLowerCase().includes(payload.toLowerCase())) {
          elem.visible = true;
        } else {
          elem.visible = false;
        }
      });
    },
    select_all: (state) => {
      state.forEach((elem) => {
        if (elem.visible) elem.filterState = true;
      });
    },
    deselect_all: (state) => {
      state.forEach((elem) => {
        if (elem.visible) elem.filterState = false;
      });
    },
  },
});

export const {
  toggle_filter: filterVesselTypeActionCreator,
  search_filter: searchFilterActionCreator,
  select_all: selectAllActionCreator,
  deselect_all: deselectAllActionCreator,
} = vesselTypeFilterSlice.actions;

export default vesselTypeFilterSlice;