import { createSlice } from '@reduxjs/toolkit';

const vesselSliderFilterSlice = createSlice({
  name: "vesselSliderFilter",
  // initialState: { risk: [0, 20], speed: [0, 35] },
  initialState: { speed: [0, 35] },
  reducers: {
    /*
    set_risk_range: (state, { payload }) => {
      state.risk[0] = payload[0];
      state.risk[1] = payload[1];
    },
    */
    set_speed_range: (state, { payload }) => {
      state.speed[0] = payload[0];
      state.speed[1] = payload[1];
    },
  },
});

export const {
  // set_risk_range: setVesselRiskFilterRangeActionCreator,
  set_speed_range: setVesselSpeedFilterRangeActionCreator,
} = vesselSliderFilterSlice.actions;

export default vesselSliderFilterSlice;