import { createSlice } from '@reduxjs/toolkit';
import { isMobile } from "react-device-detect";

const PANEL_OPEN_INITIAL_STATE = {
  controlPanel: {
    panelName: "Control Panel",
//    isOpen: true,
    isOpen: !isMobile,
    isHidden: false,
  },
  detailsPanel: {
    panelName: "Details Panel",
    isOpen: false,
    isHidden: false,
  },
  timeSlider: {
    panelName: "Time Slider",
    isOpen: true,
    isHidden: false,
  },
  searchBar: {
    panelName: "Search Bar",
    isOpen: true,
    isHidden: false,
  },
};
  
const panelOpenSlice = createSlice({
  name: "panelOpen",
  initialState: PANEL_OPEN_INITIAL_STATE,
  reducers: {
    toggleOpen: (state, { payload }) => {
      const panel = state[Object.keys(state).find((key) => key === payload)];
      panel.isOpen = !panel.isOpen;

      if (isMobile === true && ['controlPanel', 'detailsPanel'].includes(payload)){
        const otherPanelKeys = Object.keys(state).filter((key) => key !== payload);
        for (let key of otherPanelKeys){
          const otherPanel = state[key]
          otherPanel.isHidden = !otherPanel.isHidden;
        }
      }
    },
  },
});

export const {
  toggleOpen: togglePanelOpenActionCreator,
} = panelOpenSlice.actions;

export default panelOpenSlice;