import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { ThemeProvider } from "styled-components";
import { StylesProvider } from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { lightTheme, darkTheme } from "styles/themes";
import { GlobalStyle } from "styles/global";

import MapContainer from "components/map/MapContainer";
import ControlPanel from "components/control-panel/ControlPanel";
import DetailsPanel from "components/details-panel/DetailsPanel";
import TimeSlider from "components/time-slider/TimeSlider";
import SearchBar from "components/search-bar/SearchBar";

import { getTimestamp } from "utilities/datetime";

import { 
  setAllVesselsActionCreator,
} from "store/reducers/vesselDataSlice";

import { 
  setHeatmapActionCreator,
} from "store/reducers/heatmapDataSlice";
/*
import { 
  setAllCloseEncountersActionCreator,
} from "store/reducers/closeEncounterDataSlice";
*/
import {
  setMetadataActionCreator,
  setCurrentFrameActionCreator,
  getFrameTime,
} from "store/reducers/framesSlice";

import {
  setTrafficActionCreator,
} from "store/reducers/trafficSlice";

// import allCloseEncounters from "data/close_encounters.json";
// import mmsiTypeLookup from "data/mmsi_type_lookup.json";

// const METADATA_PATH = "https://raw.githubusercontent.com/jakkarintiew/frames-data/master/frames_20s/frames_metadata.json";
// const FRAMES_DIR = "https://raw.githubusercontent.com/jakkarintiew/frames-data/master/frames_20s/";

const ScreenCircularProgress = withStyles({
  indeterminate: {
    color: "#00d672",
    animationDuration: "500ms",
  },
})(CircularProgress);

const App = () => {
  // Redux states
  const dispatch = useDispatch();
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  // const currentTime = useSelector(
  //   (state) => state.dataSourceControl.currentTime
  // );
  const metadata = useSelector((state) => state.frames.metadata);
  const currentFrame = useSelector((state) => state.frames.currentFrame);
  const currentTime = useSelector(getFrameTime(currentFrame));
  const vesselLocationFrames = useSelector((state) => state.traffic.vesselLocationFrames);
  const heatmapFrames = useSelector((state) => state.traffic.heatmapFrames);
  // const closeEncounterFrames = useSelector((state) => state.traffic.closeEncounterFrames);
  const isInit = useSelector((state) => state.frames.isUpdated);
  
  const rootUrl = process.env.REACT_APP_API_ROOT_URL;
  const trafficUrl = rootUrl + "/traffic";

  const apiHeaders = {
    "x-authorization-token": process.env.REACT_APP_API_KEY
  }

  const default_start_time = getTimestamp(process.env.REACT_APP_DEFAULT_START_TIME)
  const default_end_time = getTimestamp(process.env.REACT_APP_DEFAULT_END_TIME)
  const default_interval = parseInt(process.env.REACT_APP_DEFAULT_INTERVAL)

  const setMetadata = (metadata) => {
    dispatch(setMetadataActionCreator(metadata));
  };

  const setAllVessels = (vessels) => {
    dispatch(setAllVesselsActionCreator(vessels));
  };

  const setHeatmap = (heatmap) => {
    dispatch(setHeatmapActionCreator(heatmap));
  };
  /*
  const setCloseEncounters = (closeEncounters) => {
    dispatch(setAllCloseEncountersActionCreator(closeEncounters));
  };
  */

  const setTraffic = (traffic) => {
    dispatch(setTrafficActionCreator(traffic));
  };

  const setCurrentFrame = (frame) => {
    dispatch(setCurrentFrameActionCreator(frame));
  };

  const [error, setError] = useState(null);
  // const [alertFrame, setAlertFrame] = useState(0);

  // initial time frame
  useEffect(() => {

    const initMetadata = {
      startTime: default_start_time,
      endTime: default_end_time,
      interval: default_interval,
    }
    
    setMetadata(initMetadata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Load first frame + metadata; ran once at startup
  // useEffect(() => {
  //   const getFirstFrame = async () => {
  //     try {
  //       // const promiseFrame = axios.get(FRAMES_DIR + `0_frame.json`);
  //       // const promiseMetadata = axios.get(METADATA_PATH);
  //       // const [firstFrame, metadata] = await Promise.all([
  //       //   promiseFrame,
  //       //   promiseMetadata,
  //       // ]);
  //       // incrementLoadedFrames();
  //       // setFrames((prevFrames) => ({ ...prevFrames, 0: firstFrame.data }));
  //       // setAllVessels(firstFrame.data);
  //       // const metadata = await axios.get(METADATA_PATH);
  //       // setMetadata(metadata.data);
  //       axios({
  //         method: "post",
  //         url:
  //           "https://cors-anywhere.herokuapp.com/http://52.163.54.65:80/api/v1/service/snapshot/score",
  //         data: { time_stamp_int: [currentTime] },
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${process.env.REACT_APP_SNAPSHOT_API_KEY}`,
  //         },
  //       }).then((response) => {
  //         let vessels = JSON.parse(response.data).map((vessel) => ({
  //           mmsi: vessel.mmsi,
  //           shipname: vessel.mmsi,
  //           shiptype: mmsiTypeLookup.find((v) => v.mmsi === vessel.mmsi)
  //             ? mmsiTypeLookup.find((v) => v.mmsi === vessel.mmsi).code
  //             : "UKNOWN TYPE",
  //           longitude: vessel.lon,
  //           latitude: vessel.lat,
  //           speed: vessel.speed,
  //           course: vessel.course,
  //           heading: vessel.heading,
  //           risk: vessel.risk,
  //           timestamp: vessel.time_stamp_int * 1000,
  //         }));
  //         setAllVessels(vessels);
  //       });
  //     } catch (error) {
  //       setError(error);
  //     }
  //   };
  //   getFirstFrame();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   axios({
  //     method: "post",
  //     url:
  //       "https://cors-anywhere.herokuapp.com/http://52.163.54.65:80/api/v1/service/snapshot/score",
  //     data: { time_stamp_int: [currentTime] },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${process.env.REACT_APP_SNAPSHOT_API_KEY}`,
  //     },
  //   }).then((response) => {
  //     let vessels = JSON.parse(response.data).map((vessel) => ({
  //       mmsi: vessel.mmsi,
  //       shipname: vessel.mmsi,
  //       shiptype: mmsiTypeLookup.find((v) => v.mmsi === vessel.mmsi)
  //         ? mmsiTypeLookup.find((v) => v.mmsi === vessel.mmsi).code
  //         : "UKNOWN TYPE",
  //       longitude: vessel.lon,
  //       latitude: vessel.lat,
  //       speed: vessel.speed,
  //       course: vessel.course,
  //       heading: vessel.heading,
  //       risk: vessel.risk,
  //       timestamp: vessel.time_stamp_int * 1000,
  //     }));

  //     setAllVessels(vessels);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentTime]);

  // Once metadata loaded, load remaining vessel frames
  // useEffect(() => {
  //   const getFrame = async (index) => {
  //     try {
  //       const promiseFrame = await axios.get(
  //         FRAMES_DIR + `${index}_frame.json`
  //       );
  //       incrementLoadedFrames();
  //       return { frameIndex: index, frameData: promiseFrame.data };
  //     } catch (error) {
  //       setError(error);
  //     }
  //   };
  //   const getRemainingFrames = async () => {
  //     await new Promise((r) => setTimeout(r, 3000));
  //     const totalFrames = metadata.frames.length;
  //     const promiseFrames = [];
  //     for (let index = 1; index < totalFrames; index++) {
  //       promiseFrames.push(getFrame(index));
  //     }
  //     Promise.all(promiseFrames)
  //       .then((responses) => {
  //         let framesData = {};
  //         responses.map(
  //           (elem) => (framesData[elem.frameIndex] = elem.frameData)
  //         );
  //         // console.log(framesData);
  //         setFrames((prevFrames) => ({
  //           ...prevFrames,
  //           ...framesData,
  //         }));
  //       })
  //       .catch((error) => setError(error));
  //   };
  //   if (metadata.frames.length) {
  //     getRemainingFrames();
  //   }

  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [metadata]);

  useEffect(() => {
    const getTraffic = async () => {
      try{
        var formData = new FormData();  
        formData.append("time_stamp_from", metadata.startTime);
        formData.append("time_stamp_to", metadata.endTime);
        formData.append("snapshot_interval", metadata.interval);

        const response = await axios.post(trafficUrl, formData, { headers: apiHeaders});
        setTraffic(response.data);
        setCurrentFrame(0);
      } catch (error) {
        setError(error);
      }
    }
    if(isInit)
    {
      getTraffic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  // When current frame is updated, update data
  useEffect(() => {
    if(isInit){
      var currentVesselLocations = vesselLocationFrames[currentTime];
      if(!currentVesselLocations){
        currentVesselLocations = []
      }
      var currentHeatmap = heatmapFrames[currentTime];
      if(!currentHeatmap){
        currentHeatmap = []
      }
      /*
      var currentCloseEncounters = closeEncounterFrames[currentTime];
      if(!currentCloseEncounters){
        currentCloseEncounters = []
      }
      */
      setAllVessels(currentVesselLocations);
      setHeatmap(currentHeatmap);
      // setCloseEncounters(currentCloseEncounters);
    }
      
  //   if (alertVessels.length === 0) {
  //     setAlertFrame(currentFrame >= 134 ? 179 : currentFrame + 45);
  //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFrame]);

  if (error) return <div>Error: {error.message}</div>;
  if (!isInit)
    return (
      <div className="flex h-screen">
        <div className="m-auto ">
          <ScreenCircularProgress />
        </div>
      </div>
    );
  else {
    return (
      <StylesProvider injectFirst>
        <ThemeProvider theme={darkThemeEnabled ? darkTheme : lightTheme}>
          <GlobalStyle />
          <div className="h-screen w-screen flex justify-between overflow-hidden">
            <ControlPanel />
            <div className="h-full w-full flex flex-col flex-1">
              <SearchBar />
              <TimeSlider />
            </div>
            <DetailsPanel />
          </div>
          <MapContainer
            mapStyle={
              darkThemeEnabled ? darkTheme.mapStyle : lightTheme.mapStyle
            }
          />
        </ThemeProvider>
      </StylesProvider>
    );
  }
};

export default App;
