import { createSlice } from '@reduxjs/toolkit';
import { getTimeString, getShortTimeString } from 'utilities/datetime';
import { isMobile } from "react-device-detect";



const framesInitialState = {
  metadata: {
    startTime: 0,
    endTime: 0,
    interval: 0,
  },
  currentFrame: 0,
  marks: [],
  totalFrames: 0,
  isUpdated: false,
};

const framesSlice = createSlice({
  name: "frames",
  initialState: framesInitialState,
  reducers: {
    setMetadata: (state, { payload }) => {
      state.metadata = payload;
      state.currentFrame = -1;
      state.totalFrames = Math.ceil((state.metadata.endTime - state.metadata.startTime) / state.metadata.interval);
      state.marks = []
      if (isMobile) {
        state.marks.push({value: 0, label: getShortTimeString(state.metadata.startTime)});
        state.marks.push({value: state.totalFrames, label: getShortTimeString(state.metadata.endTime)});
      } else {
        let markFrameInterval = Math.floor(state.totalFrames / 4);
        if (markFrameInterval > 0) {
          for(let timeFrame = 0; timeFrame <= state.totalFrames; timeFrame += markFrameInterval){
            let currentTime = state.metadata.startTime + timeFrame * state.metadata.interval;
            state.marks.push({value: timeFrame, label: getTimeString(currentTime)});
          }
        } else {
          state.marks.push({value: 0, label: getTimeString(state.metadata.startTime)});
          state.marks.push({value: state.totalFrames, label: getTimeString(state.metadata.endTime)});
        }
      }
      state.isUpdated = true;
    },
    setCurrentFrame: (state, { payload }) => {
      state.currentFrame = payload;
    },
  },
});

export const getFrameTime = frame => state =>  { 
  const metadata = state.frames.metadata;
  let currentUnixTime = metadata.startTime + frame * metadata.interval;
  if (currentUnixTime < metadata.startTime) {
    currentUnixTime = metadata.startTime;
  } else if (currentUnixTime > metadata.endTime) {
    currentUnixTime = metadata.endTime;
  }
  return currentUnixTime;
}

export const {
  setMetadata: setMetadataActionCreator,
  setCurrentFrame: setCurrentFrameActionCreator,
} = framesSlice.actions;

export default framesSlice;