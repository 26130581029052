import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { togglePanelOpenActionCreator } from "store/reducers/panelOpenSlice";

import PanelHeader from "./PanelHeader";
import PanelContent from "./PanelContent";
import CollapseButton from "components/common/CollapseButton";
import Settings from "components/common/icons/settings";
import ArrowLeft from "components/common/icons/arrow-left";
import Close from "components/common/icons/close";

const LeftPanelBox = styled.div`
  height: 100%;
  width: ${(props) => isMobile ? 
    2 * props.theme.sidePanel.margin.left : 
    props.theme.sidePanel.width + 2 * props.theme.sidePanel.margin.left}px;
  display: flex;
  position: relative;
`;

const StyledSidePanelContainer = styled.div`
  z-index: 99;
  height: 100%;
  width: ${(props) => props.width + 2 * props.theme.sidePanel.margin.left}px;
  display: flex;
  position: relative;
  padding-top: ${(props) => props.theme.sidePanel.margin.top}px;
  padding-right: ${(props) => props.theme.sidePanel.margin.right}px;
  padding-bottom: ${(props) => props.theme.sidePanel.margin.bottom}px;
  padding-left: ${(props) => props.theme.sidePanel.margin.left}px;
  transition: ${(props) => props.theme.transitionFast};
`;

const SidePanelInner = styled.div`
  box-shadow: ${(props) => props.theme.panelBoxShadow};
  align-items: stretch;
  background-color: ${(props) => props.theme.sidePanelBg};
  border-radius: 5px;
  height: 100%;
  width: ${(props) => props.theme.sidePanel.width}px;
`;

const ControlButton = ({panel, onClick}) => {
  if (panel.isOpen){
    if(isMobile){
      return (
        <CollapseButton onClick={onClick} style={{ width: "40px", height: "40px", right: "-20px" }}>
          <Close height="25px"/>
        </CollapseButton>
      );
    } else {
      return (
        <CollapseButton onClick={onClick} style={{ right: "-5px" }}>
          <ArrowLeft height="12px"/>
        </CollapseButton>
      );
    }
  } else {

    return (
      <CollapseButton onClick={onClick} style={{ width: "40px", height: "40px", left: `${isMobile? "5px":"20px"}` }}>
        <Settings height="25px"/>
      </CollapseButton>
    );
  }
}

const SidePanel = () => {  
  // Redux states
  const dispatch = useDispatch();
  const panelOpen = useSelector((state) => state.panelOpen);
  const togglePanelOpen = (panelKey) => {
    dispatch(togglePanelOpenActionCreator(panelKey));
  };

  // Component constants
  const width = 300;
  const panelKey = "controlPanel";
  const panel =
    panelOpen[Object.keys(panelOpen).find((key) => key === panelKey)];

  const handleOnClick = (event) => {
    togglePanelOpen(panelKey);
  };

  return (
    <LeftPanelBox style={{ visibility: `${panel.isHidden ? "collapse" : "visible"}` }} >
      <StyledSidePanelContainer width={panel.isOpen ? width : 0}>
        {panel.isOpen && (
          <SidePanelInner className="flex flex-col">
            <PanelHeader />
            <PanelContent />
          </SidePanelInner>
        )}
        <ControlButton onClick={handleOnClick} panel={panel} />
      </StyledSidePanelContainer>
    </LeftPanelBox>
  );
};

export default SidePanel;
