import { combineReducers } from 'redux'

import closeEncounterDataSlice from './closeEncounterDataSlice'
import dataSourceControlSlice from './dataSourceControlSlice'
import framesSlice from './framesSlice'
import heatmapDataSlice from './heatmapDataSlice'
import layerVisibilitySlice from './layerVisibilitySlice'
import mapViewSlice from './mapViewSlice'
import panelOpenSlice from './panelOpenSlice'
import pathDataSlice from './pathDataSlice'
import themeSlice from './themeSlice'
import trafficSlice from './trafficSlice'
import vesselDataSlice from './vesselDataSlice'
import vesselSliderFilterSlice from './vesselSliderFilterSlice'
import vesselTypeFilterSlice from './vesselTypeFilterSlice'

export default combineReducers({
  mapView: mapViewSlice.reducer,
  panelOpen: panelOpenSlice.reducer,
  layerVisibility: layerVisibilitySlice.reducer,
  darkThemeEnabled: themeSlice.reducer,
  vesselTypeFilter: vesselTypeFilterSlice.reducer,
  vesselSliderFilter: vesselSliderFilterSlice.reducer,
  frames: framesSlice.reducer,
  pathData: pathDataSlice.reducer,
  vesselData: vesselDataSlice.reducer,
  dataSourceControl: dataSourceControlSlice.reducer,
  traffic: trafficSlice.reducer,
  heatmapData: heatmapDataSlice.reducer,
  closeEncounterData: closeEncounterDataSlice.reducer,
});